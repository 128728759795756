<template>
  <div class="lotteries-page">
    <a-row type="flex" justify="space-between">
      <a-col :lg="10" :md="10" :sm="24">
        <h2 class="list-title">
          <a-icon type="folder" class="list-icon" />
          <span>{{ labels.title }}</span>
        </h2>
        <p class="list-subtitle">{{ labels.subtitle }}</p>
      </a-col>
      <a-col :lg="14" :md="14" :sm="24" :xs="24">
        <a-row
          type="flex"
          justify="end"
          :gutter="16"
          :style="{
            alignItems: 'flex-end',
            marginTop: '25px',
            marginBottom: '10px',
          }"
        >
          <a-col :lg="18" :md="18" :sm="20" :xs="16">
            <a-input-search
              :placeholder="labels.placeholderSearch"
              style="width: 100%"
              @search="handleSearch"
              allowClear
            />
          </a-col>
          <a-col
            :lg="6"
            :md="6"
            :sm="4"
            :xs="8"
            :style="{ textAlign: 'right' }"
            v-if="canCreate"
          >
            <router-link :to="{ name: 'admin.lotteries.create' }">
              <a-button type="default" ghost icon="plus" class="add-button">
                <span>{{ labels.add }}</span>
              </a-button>
            </router-link>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <StandardTable
          ref="standardTable"
          :fetchService="fetchLotteries"
          :columns="columns"
          :locale="locale"
          :handleShowTotal="handleShowTotal"
          v-bind="!isMobile && { scroll: { x: true } }"
          :paginationType="isMobile ? 'arrows' : 'numbers'"
          :customRow="handleCustomRow"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import labels from "@/utils/labels";
import StandardTable from "../../../components/core/table/StandardTable.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import _ from "lodash";
import {
  DATE_FORMAT,
  adminPageTitle,
  hasRoles,
  roles,
} from "../../../utils/utils";
import moment from "moment";
export default {
  components: { StandardTable },
  name: "LotteryList",
  metaInfo: {
    title: adminPageTitle("Loterias"),
  },
  filters: {
    ucwords(value) {
      if (!value) return "";
      value = value.toString();
      return value
        .split(" ")
        .map((item) => _.capitalize(item))
        .join(" ");
    },
  },
  data() {
    return {
      labels: labels.lotteries,
      search: "",
      activeRow: null,
    };
  },
  methods: {
    ...mapActions("lotteries", ["fetchLotteries", "deleteLottery"]),
    handleReloadTable() {
      let filtersTable = this.$refs.standardTable.filters;
      let result = { ...filtersTable };
      this.$refs.standardTable.handleFilters(result);
    },
    handleSearch(search) {
      this.$refs.standardTable.handleSearch(search);
    },
    handleShowTotal(total, range) {
      return `${range[0]}-${range[1]} de ${total}`;
    },
    confirmDelete(id) {
      this.$confirm({
        title: this.labels.confirm.title,
        content: this.labels.confirm.subtitle,
        okText: this.labels.confirm.confirmButtonText,
        okType: "danger",
        icon: "delete",
        cancelText: this.labels.confirm.cancelButtonText,
        onOk: async () => {
          try {
            await this.deleteLottery(id);
            this.$message.success(labels.deletedSuccess);
            this.handleReloadTable();
          } catch (error) {
            this.$message.error(
              error?.response?.data?.message ||
                error?.response?.message ||
                error.message ||
                labels.deleteError
            );
          }
        },
      });
    },
    handleCustomRow(_, index) {
      return {
        on: {
          click: () => {
            this.activeRow = this.activeRow === index ? null : index;
          },
        },
      };
    },
  },
  computed: {
    ...mapGetters("adminAuth", ["getLoggedUser"]),
    ...mapState("layout", ["screenWidth"]),
    isMobile() {
      return this.screenWidth < 600;
    },
    canDelete() {
      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [roles.admin.ROLE_LOTTERIES_DELETE]);
    },
    canCreate() {
      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [roles.admin.ROLE_LOTTERIES_CREATE]);
    },
    locale() {
      return {
        emptyText: this.labels.table.emptyText,
      };
    },
    columns() {
      return [
        {
          title: this.labels.table.columns.abbreviated,
          dataIndex: "abbreviated",
          key: "abbreviated",
          sorter: true,
          hidden: this.isMobile,
        },
        {
          title: this.labels.table.columns.name,
          dataIndex: "name",
          key: "name",
          sorter: true,
          hidden: this.isMobile,
        },
        {
          title: this.labels.table.columns.country,
          dataIndex: "country",
          key: "country",
          sorter: true,
          hidden: this.isMobile,
        },
        {
          title: this.labels.table.columns.createdAt,
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return this.$moment(text).format("DD/MM/YYYY H:mm:ss A");
          },
        },
        {
          title: this.labels.table.columns.status.title,
          dataIndex: "status",
          key: "status",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return (
              <a-tag color={text ? "green" : "red"}>
                {text
                  ? this.labels.table.columns.status.active
                  : this.labels.table.columns.status.inactive}
              </a-tag>
            );
          },
        },
        {
          title: this.labels.table.columns.action.title,
          dataIndex: "action",
          key: "action",
          width: "120px",
          hidden: this.isMobile,
          customRender: (text, record) => {
            return (
              <div class="action-buttons">
                <router-link
                  to={{
                    name: "admin.lotteries.edit",
                    params: { id: record.id },
                  }}
                  title={this.labels.table.columns.action.edit}
                >
                  <a-button
                    type="link"
                    icon="edit"
                    class="action-button-edit"
                  />
                </router-link>
                {this.canDelete && (
                  <a-button
                    type="link"
                    icon="delete"
                    class="action-button-delete"
                    onClick={() => this.confirmDelete(record.id)}
                    title={this.labels.table.columns.action.delete}
                  />
                )}
              </div>
            );
          },
        },
        {
          title: this.labels.table.columns.lotteries,
          dataIndex: "lotteries",
          key: "lotteries",
          sorter: false,
          hidden: !this.isMobile,
          customRender: (_, record, index) => {
            // Card item design
            const color = record.status ? "#28a745" : "#f50";
            const week_day = moment().locale("en").format("dddd").toLowerCase();
            const today_close_hour = moment(
              record[`${week_day}_end_time`],
              "HH:mm:ss"
            ).format("hh:mm A");

            return (
              <div class="list-item">
                <div class="list-item-head">
                  <div
                    class="list-item-head-header"
                    style={{ borderColor: color }}
                  >
                    <span>
                      {this.$options.filters.ucwords(
                        this.$moment(record.created_at).format(
                          DATE_FORMAT.MOMENT_DAY_MONTH_YEAR
                        )
                      )}
                    </span>
                    <small>
                      {this.$moment(record.created_at)
                        .format(DATE_FORMAT.MOMENT_TIME)
                        .toUpperCase()}
                    </small>
                  </div>
                  <div class="list-item-head-content">
                    <div class="list-item-head-content-abbreviated">
                      <span>{record.abbreviated}</span>
                    </div>
                    <div class="list-item-head-content-name">
                      <span>{record.name}</span>
                    </div>
                    <div class="list-item-head-content-close-time">
                      <span>
                        <font-awesome-icon
                          icon={["fas", "stopwatch"]}
                          class="anticon"
                        />
                        {today_close_hour}
                      </span>
                    </div>
                  </div>
                  <div class="list-item-head-footer">
                    <div class="list-item-head-footer-status">
                      <a-tag color={record.status ? "green" : "red"}>
                        {record.status
                          ? this.labels.table.columns.status.active
                          : this.labels.table.columns.status.inactive}
                      </a-tag>
                    </div>
                  </div>
                </div>
                {this.activeRow === index && (
                  <div class="list-item-actions">
                    <a-button-group>
                      <router-link
                        class="edit-button ant-btn ant-btn-primary ant-btn-block"
                        to={{
                          name: "admin.lotteries.edit",
                          params: { id: record.id },
                        }}
                        title={this.labels.table.columns.action.edit}
                      >
                        {this.labels.table.columns.action.edit}
                      </router-link>
                      {this.canDelete && (
                        <a-button
                          class="delete-button ant-btn ant-btn-danger"
                          onClick={() => this.confirmDelete(record.id)}
                          title={this.labels.table.columns.action.delete}
                          type="danger"
                        >
                          {this.labels.table.columns.action.delete}
                        </a-button>
                      )}
                    </a-button-group>
                  </div>
                )}
              </div>
            );
          },
        },
      ].filter((column) => !column.hidden);
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.lotteries-page {
  .list {
    &-title {
      margin-bottom: 10px;
      .list-icon {
        margin-right: 10px;
      }
    }
    &-subtitle {
      margin-bottom: 10px;
    }
  }
  .add-button {
    color: color(primary);
    border-color: color(primary);
  }
  .action-buttons {
    display: flex;
    justify-content: space-between;
    .action-button-edit {
      color: color(primary);
    }
    .action-button-delete {
      color: color(danger);
    }
  }
  .list-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    &-head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 6px;
      &-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;
        max-width: 25%;
        width: 25%;
        border-right: 4px solid color(border);
        .success {
          color: color(success);
        }
        .warning {
          color: color(warning);
        }
        .danger {
          color: color(danger);
        }
        span {
          font-size: 14px;
          font-weight: 600;
        }
        small {
          font-size: 12px;
          font-weight: 400;
          padding-right: 4px;
        }
      }
      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2px;
        max-width: 50%;
        width: 50%;
        overflow: hidden;
        &-abbreviated {
          span {
            font-size: 16px;
            font-weight: 600;
            text-overflow: ellipsis;
          }
        }
        &-name {
          span {
            font-size: 14px;
            font-weight: 500;
            text-overflow: ellipsis;
          }
        }
        &-close-time {
          span {
            font-size: 12px;
            font-weight: 400;
            text-overflow: ellipsis;
            font-style: italic;
            .anticon {
              margin-right: 4px;
              color: color(danger);
              font-size: 14px;
            }
          }
        }
      }
      &-footer {
        max-width: 25%;
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: right;
        gap: 2px;
        &-status {
          width: 100%;
          span {
            font-size: 12px;
            font-weight: 400;
          }
          .ant-tag {
            padding: 0;
            margin: 0;
            padding-left: 2px;
            padding-right: 2px;
          }
        }
      }
    }
    &-actions {
      width: 100%;
      margin-top: 10px;
      .ant-btn-group {
        width: 100%;
        .more-info-button,
        .edit-button,
        .delete-button {
          width: 100%;
        }
      }
    }
  }
}
</style>
